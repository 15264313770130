import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { AdSide1, AdSide2, AdFooter } from "../components/ads";

const ShowAds = ({ adCount }) => {
  var ads = <></>;

  for (var x = 2; x <= adCount; x++) {
    ads = (
      <>
        {ads}
        <div className="ad_vertical mb-4">
          <AdSide2 />
        </div>
      </>
    );
  }

  ads = (
    <Col md={4}>
      <div className="ad_vertical mb-4">
        <AdSide1 />
      </div>
      {ads}
    </Col>
  );

  return ads;
};

const BlogPost = ({ children, title, header, adCount = 1 }) => {
  return (
    <Container>
      <Row>
        <Col>
          {header}
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <h1 itemProp="headline">{title}</h1>
            </div>
          </div>
          <article className="blog-post" itemScope itemType="http://schema.org/Article">
            {children}
          </article>
        </Col>
        {adCount > 0 ? <ShowAds adCount={adCount} /> : <></>}
      </Row>
      {adCount > 0 ?
      <Row>
        <Col>
          <div className="ad_horizontal mb-4">
            <AdFooter />
          </div>
        </Col>
      </Row>
      : <></>
      }
    </Container>
  );
};

export default BlogPost;
