import React, { useEffect } from "react"

const { NODE_ENV } = process.env

export const AdSide1 = () => { 
  useEffect(() => {
    console.log('running adsbygoogle AdSide1');
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  var adClass = "";
  var ad = `
    <!-- ILIR Side 1 -->
    <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-2790982044180181"
      data-ad-slot="2892732006"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  `;
  if (NODE_ENV === "development") {
    adClass = "ad-development-side1";
    ad = "";
  }

  return (
    <div className="ad-top">
      <div className="d-flex justify-content-center ad-title">ADVERTISEMENT</div>
      <div className={adClass} dangerouslySetInnerHTML={{ __html: ad }} />
    </div>
  );

}
//<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2790982044180181"
//      crossorigin="anonymous"></script>

export const AdSide2 = () => {
  useEffect(() => {
    console.log('running adsbygoogle AdSide2');
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  var adClass = "";
  var ad = `
    <!-- ILIR Side 2 -->
    <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-2790982044180181"
      data-ad-slot="8635179120"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  `;
  if (NODE_ENV === "development") {
    adClass = "ad-development-side2";
    ad = "";
  }

  return (
    <div>
      <div className={adClass} dangerouslySetInnerHTML={{ __html: ad }} />
    </div>
  );
}

export const AdInArtricle = () => {
  useEffect(() => {
    console.log('running adsbygoogle AdInArtricle');
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  var adClass = "";
  var ad = `
    <ins class="adsbygoogle"
      style="display:block; text-align:center;"
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client="ca-pub-2790982044180181"
      data-ad-slot="2052834181"></ins>
       `;
  if (NODE_ENV === "development") {
    adClass = "ad-development-inarticle";
    ad = "";
  }

  return (
    <div className="ad-top ad-bottom mb-2">
      <div className="d-flex justify-content-center ad-title">ADVERTISEMENT</div>
      <div className={"mb-2 " + adClass} dangerouslySetInnerHTML={{ __html: ad }} />
    </div>
  );
}


export const AdFooter = () => {
  useEffect(() => {
    console.log('running adsbygoogle AdFooter');
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  var adClass = "";
  var ad = `
  <!-- ILIR Footer -->
  <ins class="adsbygoogle"
       style="display:block"
       data-ad-client="ca-pub-2790982044180181"
       data-ad-slot="3606924163"
       data-ad-format="auto"
       data-full-width-responsive="true"></ins>
       `;
  if (NODE_ENV === "development") {
    adClass = "ad-development-footer";
    ad = "";
  }

  return (
    <div>
      <div className="d-flex justify-content-center ad-title">ADVERTISEMENT</div>
      <div className={adClass} dangerouslySetInnerHTML={{ __html: ad }} />
    </div>
  );
}
